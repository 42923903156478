<template>
  <a-layout>
    <div class="nav">
      <Breadcrumb>
        <BreadcrumbItem>订单查看</BreadcrumbItem>
      </Breadcrumb>
    </div>
    <section class="card__container">
      <div class="container__title">基本信息</div>
      <a-form class="advanced-search-form">
        <a-row>
          <a-col :span="8">
            <a-form-item label="订单号">
              <div>
                {{ info.orderSn }}
              </div>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="下单机构">
              <div>{{ info.orderCorporationName }}</div>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="买方">
              <div>{{ info.purchaserCorporationName }}</div>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="8">
            <a-form-item label="卖方">
              <div>{{ info.producerCorporationName }}</div>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="订单类型">
              {{ info.orderTypeValue }}
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="下单时间">
              {{ tsFormat(info.createTime) }}
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="8">
            <a-form-item label="出厂价总额">
              {{ info.factoryTotalPrice }} 元
            </a-form-item>
          </a-col>

          <a-col :span="8">
            <a-form-item label="订单状态">
              <span
                v-if="
                  fromRouterName === 'PurchaseOrderListFactory' ||
                    storeRouterName === 'PurchaseOrderListFactory'
                "
              >
                {{ info.nodeStatus }}
              </span>
              <span v-else>
                {{ info.orderStatusValue }}
              </span>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="交付周期">
              {{ info.orderCycle || " - " }}
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="8">
            <a-form-item label="下定月份">
              <div>{{ info.installmentSn }}</div>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="订单备注">
              <div>{{ info.orderMemo || " - " }}</div>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </section>
    <section class="card__container">
      <div class="container__title">收货信息</div>
      <a-form>
        <a-row>
          <a-col :span="8">
            <a-form-item label="收货人姓名">
              <div>{{ info.consigneeRealName }}</div>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="收货人电话">
              <div>{{ info.consigneeTel }}</div>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="收货地址">
              <div>{{ info.consigneeAddress }}</div>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </section>
    <section class="card__container" v-if="agreementData.length > 0">
      <div class="container__title">合同信息</div>
      <a-table
        class="agreement__table"
        :columns="agreementColumns"
        :data-source="agreementData"
        :loading="loading"
        :pagination="false"
        :rowKey="(record, index) => index"
      >
        <template slot="signTime" slot-scope="text">
          {{ text ? tsFormat(text) : "-" }}
        </template>
        <template slot="action" slot-scope="text, record">
          <a
            :href="imageBaseUrl + record.contractFile.fileUrl"
            v-if="record.contractFile"
            target="_blank"
          >
            预览
          </a>
        </template>
      </a-table>
    </section>
    <section class="card__container" v-if="saleBillNum > 0">
      <div class="container__title">
        物流信息
        <span v-if="saleBillNum > 0">（{{ saleBillNum }}）条</span>
        <a
          class="check__all"
          v-if="saleBillNum > 1"
          @click="onCheckDeliverDetail"
        >
          查看全部
        </a>
      </div>
      <div class="sale__title">第一批明细：</div>
      <a-form class="advanced-search-form">
        <a-row>
          <a-col :span="8">
            <a-form-item label="发货时间">
              {{
                saleBillInfo.logisticsSendTime
                  ? tsFormat(saleBillInfo.logisticsSendTime)
                  : "-"
              }}
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="商品金额">
              {{ calculatePrice(inventoryData) }} 元
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="物流公司">
              {{
                saleBillInfo.logisticsName ? saleBillInfo.logisticsName : "-"
              }}
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="8">
            <a-form-item label="物流单号">
              {{ saleBillInfo.logisticsSn ? saleBillInfo.logisticsSn : "-" }}
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="物流电话">
              {{ saleBillInfo.logisticsTel ? saleBillInfo.logisticsTel : "-" }}
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="24">
            <a-form-item label="发货清单">
              <a-table
                :columns="inventoryColumns"
                :data-source="inventoryData"
                :loading="inventoryLoading"
                :pagination="false"
                :rowKey="(record, index) => index"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <div v-if="receiveBill && receiveBill.length > 0">
          <div v-for="item in receiveBill" :key="item.receivingId">
            <a-row>
              <a-col :span="8">
                <a-form-item label="收货时间">
                  {{ item.signTime ? tsFormat(item.signTime) : "-" }}
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item label="发票信息">
                  <a>查看</a>
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item label="商品金额">
                  {{ calculatePrice(item.deliverVoList) }}
                </a-form-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="24">
                <a-form-item label="收货清单">
                  <a-table
                    :columns="inventoryColumns"
                    :data-source="item.deliverVoList"
                    :pagination="false"
                    :rowKey="(record, index) => index"
                  />
                </a-form-item>
              </a-col>
            </a-row>
          </div>
        </div>
      </a-form>
    </section>
    <section class="card__container">
      <div
        class="container__title between"
        v-if="info.productTypeValue === '整车'"
      >
        <div>商品信息（整车）</div>
        <a-button type="primary" @click="standardVisible = true">
          查看采购标准
        </a-button>
      </div>
      <div class="container__title" v-else>商品信息（配件）</div>
      <a-table
        class="commodity__table"
        :columns="commodityColumns"
        :data-source="instantOrderSkus"
        :loading="loading"
        :pagination="false"
        :rowKey="(record, index) => index"
      ></a-table>
    </section>
    <section class="card__container">
      <div class="container__title">付款流水</div>
      <a-table
        class="flow__table"
        :columns="flowColumns"
        :data-source="flowList"
        :loading="flowLoading"
        :pagination="false"
        :rowKey="(record, index) => index"
      >
        <template slot="huifuSeqId" slot-scope="text">
          {{ text || "-" }}
        </template>
        <template slot="paymentTime" slot-scope="text">
          {{ text ? tsFormat(text) : "-" }}
        </template>
      </a-table>
    </section>
    <section class="card__container low__bottom">
      <div class="container__title between">
        流程日志
        <a @click="onShowProcessImg" class="process_img" v-if="!isFactory">
          点击查看流程图
        </a>
        <div v-viewer class="images">
          <img
            v-if="
              info.purchaserCorporationDistributorTypeValue === '渠道终端' &&
                info.producerCorporationDistributorTypeValue === '原厂库'
            "
            src="../../../assets/order/img_zd_order.png"
            :data-source="require('../../../assets/order/img_zd_order.png')"
            alt="流程图"
          />
          <img
            v-else-if="
              info.purchaserCorporationDistributorTypeValue === '渠道终端' &&
                info.producerCorporationDistributorTypeValue === '服务中心'
            "
            src="../../../assets/order/cg-end-service.png"
            alt="流程图"
          />
          <img
            v-else
            src="../../../assets/order/img_service_order.png"
            :data-source="
              require('../../../assets/order/img_service_order.png')
            "
            alt="流程图"
          />
        </div>
      </div>
      <a-table
        class="log__table"
        :columns="logColumns"
        :data-source="logData"
        :loading="logLoading"
        :pagination="false"
        :rowKey="(record, index) => index"
      >
        <template slot="createTime" slot-scope="text">
          {{ tsFormat(text) }}
        </template>
        <template slot="reason" slot-scope="text">
          {{ text || " - " }}
        </template>
      </a-table>
    </section>
    <div class="top__back__container" v-if="showTop" @click="onScrollTop">
      <img src="../../../assets/order/icon-top.png" alt="icon" />
      回到顶部
    </div>
    <!--  设置整车采购标准弹窗  -->
    <a-modal v-model="standardVisible" title="整车采购标准" :footer="null">
      <div class="standard__container">
        <a-input
          v-model="standInfo.purchaseMinVolume"
          class="input__standard"
          disabled
        />
        <div class="unit">m³</div>
        <div class="standard__word">≤ 商品装运总体积 ≤</div>
        <a-input
          v-model="standInfo.purchaseMaxVolume"
          class="input__standard"
          disabled
        />
        <div class="unit">m³</div>
      </div>
      <div class="standard__container top">
        <a-input
          v-model="standInfo.purchaseMinWeight"
          class="input__standard"
          disabled
        />
        <div class="unit">kg</div>
        <div class="standard__word">≤ 商品总重量 ≤</div>
        <a-input
          v-model="standInfo.purchaseMaxWeight"
          class="input__standard"
          disabled
        />
        <div class="unit">kg</div>
      </div>
    </a-modal>
  </a-layout>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb";
import BreadcrumbItem from "@/components/BreadcrumbItem";
import { tsFormat } from "@/components/DateUtils";
import { fetchStandard } from "@/services/ProductService";
import {
  fetchOrderDetail,
  fetchOrderLog,
  fetchPayResult
} from "@/services/OrderManageService";
import { imageBaseUrl } from "@/services/HttpService";
import {
  fetchReceiveBillBySaleBill,
  fetchSaleBillByOrderSn
} from "@/services/OrderService";

const commodityColumns = [
  {
    title: "商品编码",
    dataIndex: "skuCode",
    width: "20%"
  },
  {
    title: "商品名称",
    dataIndex: "skuName",
    width: "30%"
  },
  {
    title: "出厂单价（元）",
    dataIndex: "skuFactoryPrice",
    width: "10%"
  },
  {
    title: "采购数量",
    dataIndex: "purchaseQuantity",
    width: "10%"
  },
  {
    title: "出厂总价（元）",
    dataIndex: "skuFactoryTotalPrice",
    width: "10%"
  },
  {
    title: "装运总体积（m³）",
    dataIndex: "totalVolume",
    width: "10%"
  },
  {
    title: "装运总质量（kg）",
    dataIndex: "totalWeight",
    width: "10%"
  }
];
const logColumns = [
  {
    title: "执行人",
    dataIndex: "realName",
    width: "15%"
  },
  {
    title: "机构名称",
    dataIndex: "corporationName",
    width: "15%"
  },
  {
    title: "渠道类型",
    dataIndex: "distributorType",
    width: "15%"
  },
  {
    title: "操作时间",
    dataIndex: "createTime",
    scopedSlots: { customRender: "createTime" },
    width: "20%"
  },
  {
    title: "执行动作",
    dataIndex: "operation",
    width: "5%"
  },
  {
    title: "审核意见",
    dataIndex: "reason",
    scopedSlots: { customRender: "reason" },
    width: "30%"
  }
];

const flowColumns = [
  {
    title: "流水号",
    dataIndex: "huifuSeqId",
    scopedSlots: { customRender: "huifuSeqId" },
    width: "15%"
  },
  {
    title: "付款机构",
    dataIndex: "paymentCorporationName",
    width: "20%"
  },
  {
    title: "收款机构",
    dataIndex: "receiveCorporationName",
    width: "20%"
  },
  {
    title: "应付款金额（元）",
    dataIndex: "actualPayment",
    width: "10%"
  },
  // {
  //   title: "实际付款金额（元）",
  //   dataIndex: "accountPaid",
  //   width: "10%"
  // },
  {
    title: "付款时间",
    dataIndex: "paymentTime",
    scopedSlots: { customRender: "paymentTime" },
    width: "15%"
  },
  {
    title: "状态",
    dataIndex: "paymentStatus",
    width: "10%"
  }
];
const inventoryColumns = [
  {
    title: "商品名称",
    dataIndex: "skuName",
    width: "40%"
  },
  {
    title: "商品编号",
    dataIndex: "skuCode",
    width: "15%"
  },
  {
    title: "商品序列号",
    dataIndex: "goodsSn",
    width: "15%"
  },
  {
    title: "商品单价（元）",
    dataIndex: "skuFactoryPrice",
    width: "15%"
  },
  {
    title: "商品数量",
    dataIndex: "quantity",
    width: "15%"
  }
];
const agreementColumns = [
  {
    title: "签署方1",
    dataIndex: "partyFirst",
    width: "20%"
  },
  {
    title: "签署方2",
    dataIndex: "partySecond",
    width: "20%"
  },
  {
    title: "合同名称",
    dataIndex: "contractName",
    width: "30%"
  },
  {
    title: "签订时间",
    dataIndex: "signTime",
    scopedSlots: { customRender: "signTime" },
    width: "20%"
  },
  {
    title: "操作",
    dataIndex: "action",
    scopedSlots: { customRender: "action" },
    width: "10%"
  }
];

export default {
  name: "ForwardPurchaseSubOrder",
  components: { Breadcrumb, BreadcrumbItem },
  data() {
    return {
      standardVisible: false,
      purchaseMinVolume: "",
      purchaseMaxVolume: "",
      purchaseMinWeight: "",
      purchaseMaxWeight: "",
      commodityColumns,
      loading: false,
      logColumns,
      logLoading: false,
      logData: [],
      orderCycle: "",
      dateType: "day",
      orderSn: this.$route.params.orderSn,
      type: this.$route.params.type,
      info: {},
      tsFormat,
      approvalMemo: "",
      instantOrderSkus: [],
      showTop: false,
      standInfo: {},
      fromRouterName: "",
      storeRouterName: "",
      flowColumns,
      flowList: [],
      flowLoading: false,
      isFactory: false,
      status: this.$route.params.status,
      agreementColumns,
      agreementData: [],
      saleBillInfo: {},
      saleBillNum: 0,
      inventoryColumns,
      inventoryData: [],
      inventoryLoading: false,
      receiveBill: [],
      imageBaseUrl
    };
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.fromRouterName = from.name;
      if (vm.fromRouterName) {
        sessionStorage.setItem("fromRouterName", vm.fromRouterName);
      }
    });
  },
  beforeRouteLeave(to, from, next) {
    sessionStorage.removeItem("fromRouterName");
    next();
  },
  mounted() {
    if (sessionStorage.getItem("fromRouterName")) {
      this.storeRouterName = sessionStorage.getItem("fromRouterName");
    }
    let roles = localStorage.getItem("roles");
    if (roles && roles.length > 0) {
      this.isFactory = roles.includes("FactoryAdmin");
    }
    this.loadPayBill();
    this.loadSaleBill();
    this.loadDetail();
    this.loadStandInfo();
    const that = this;
    window.onscroll = function() {
      const scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      const windowHeight =
        document.documentElement.clientHeight || document.body.clientHeight;
      const scrollHeight =
        document.documentElement.scrollHeight || document.body.scrollHeight;
      if (scrollTop + windowHeight >= scrollHeight) {
        that.showTop = true;
      } else {
        that.showTop = false;
      }
    };
  },
  methods: {
    // 查询采购标准
    loadStandInfo() {
      fetchStandard(localStorage.getItem("factoryId") || "").then(resp => {
        if (resp.data.code === "SUCCESS") {
          this.standInfo = resp.data.data || {};
        }
      });
    },
    // 加载详情
    loadDetail() {
      this.loading = true;
      const params = {
        orderSn: this.orderSn,
        type: this.transferStatus()
      };
      fetchOrderDetail(params).then(resp => {
        this.loading = false;
        if (resp.data.code === "SUCCESS") {
          this.info = resp.data.data;
          this.agreementData = this.info.contracts || [];
          this.instantOrderSkus = this.calculate(this.info.instantOrderSkus);
          this.loadLogList();
        }
      });
    },
    transferStatus() {
      let type = "";
      switch (this.status) {
        case "卖家审核中":
        case "买家付款中":
        case "签约中":
        case "已取消":
        case "已退回":
          type = "approval";
          break;
        case "待付款审核":
        case "交易成功":
          type = "payment-approval";
          break;
        case "卖家发货中":
          type = "deliver";
          break;
        case "买家收货中":
          type = "receive";
          break;
        default:
          type = "approval";
          break;
      }
      return type;
    },
    // 加载流程日志
    loadLogList() {
      this.logLoading = true;
      fetchOrderLog(
        this.info.batchOrderSn ? this.info.batchOrderSn : this.orderSn
      ).then(resp => {
        this.logLoading = false;
        if (resp.data.code === "SUCCESS") {
          this.logData = resp.data.data;
        }
      });
    },
    // 计算
    calculate(list) {
      let arr = [...list];
      if (list && list.length > 0) {
        let totalPrice = 0;
        let num = 0;
        let volume = 0;
        let weight = 0;
        list.forEach(item => {
          num = num + parseInt(item.purchaseQuantity);
          totalPrice = totalPrice + parseFloat(item.skuFactoryTotalPrice);
          volume = volume + parseFloat(item.totalVolume);
          weight = weight + parseFloat(item.totalWeight);
        });
        const params = {
          skuCode: "合计：",
          purchaseQuantity: num,
          skuFactoryTotalPrice: totalPrice.toFixed(2),
          totalVolume: volume,
          totalWeight: weight
        };
        arr.push(params);
      }
      return arr;
    },
    // 回到顶部
    onScrollTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    },
    // 查付款单
    loadPayBill() {
      this.flowLoading = true;
      fetchPayResult(this.orderSn)
        .then(resp => {
          this.flowLoading = false;
          if (resp.data.code === "SUCCESS") {
            const data = resp.data.data;
            if (data && data.length > 0) {
              this.flowList = data;
            }
          }
        })
        .catch(() => {
          this.flowLoading = false;
        });
    },
    // 查看流程图
    onShowProcessImg() {
      const viewer = this.$el.querySelector(".images").$viewer;
      viewer.show();
    },
    // 获取发货单
    loadSaleBill() {
      this.inventoryLoading = true;
      fetchSaleBillByOrderSn(this.orderSn)
        .then(resp => {
          this.inventoryLoading = false;
          if (resp.data.code === "SUCCESS") {
            const data = resp.data.data;
            if (data && data.length > 0) {
              let list = data;
              this.saleBillNum = list.length;
              this.saleBillInfo = list[0];
              this.inventoryData = this.saleBillInfo.detailVoList;
              this.loadReceiveBill(this.saleBillInfo.consignmentId);
            }
          }
        })
        .catch(() => {
          this.inventoryLoading = false;
        });
    },
    // 获取收货单
    loadReceiveBill(consignmentId) {
      fetchReceiveBillBySaleBill(consignmentId).then(resp => {
        if (resp.data.code === "SUCCESS") {
          this.receiveBill = resp.data.data;
        }
      });
    },
    // 计算商品金额
    calculatePrice(list) {
      let num = 0;
      if (list && list.length > 0) {
        list.forEach(item => {
          num = num + item.skuFactoryPrice * item.quantity;
        });
        num = num.toFixed(2);
      } else {
        num = "-";
      }
      return num;
    }
  }
};
</script>

<style scoped>
.container__title {
  color: rgba(16, 16, 16, 1);
  font-size: 14px;
  font-weight: bold;
}

/deep/ .ant-form-item {
  margin: 10px 40px 0 0;
}

/deep/ .ant-form-item .ant-form-item-label {
  min-width: 85px !important;
  text-align: left !important;
}

/deep/ .ant-form-item-label > label {
  color: rgba(102, 102, 102, 0.8);
  font-size: 14px;
}

.between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.standard__container {
  display: flex;
  align-items: flex-end;
}

.input__standard {
  width: 100px;
  margin-right: 10px;
}

.standard__word {
  min-width: 120px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 10px;
}

.top {
  margin-top: 20px;
}

.commodity__table,
.log__table {
  margin-top: 20px;
}

.bottom {
  margin-bottom: 140px;
}

/deep/ .ant-form-item {
  display: flex;
}

/deep/ .ant-table-title {
  background-color: rgba(235, 240, 255, 1);
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
}

.top__back__container {
  border-radius: 4px 0px 0px 4px;
  background-color: rgba(16, 16, 16, 0.4);
  color: white;
  box-sizing: border-box;
  padding: 8px 10px 8px 15px;
  position: fixed;
  right: 0;
  bottom: 200px;
  z-index: 10000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  cursor: pointer;
}

.top__back__container img {
  width: 24px;
  height: 24px;
}

.flow__table {
  margin-top: 20px;
}

.tip {
  display: flex;
  align-items: center;
  color: rgba(153, 153, 153, 1);
  font-size: 12px;
}

.tip /deep/ .anticon {
  margin-right: 6px;
}

.between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.images img {
  display: none;
}

.process_img {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  font-weight: unset;
}

.agreement__table {
  margin-top: 20px;
}

.sale__title {
  margin-top: 10px;
}
</style>
